import { initializeApp } from 'firebase/app'
import { getCountFromServer, getFirestore, doc, setDoc, addDoc, updateDoc, getDoc, getDocs, collection, serverTimestamp, deleteDoc, query, where, limit, orderBy} from 'firebase/firestore'
import { getStorage, deleteObject, ref, getDownloadURL } from 'firebase/storage'

import {
    getAuth,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
    GoogleAuthProvider,
    signInWithPopup,
    reauthenticateWithPopup,
    // onAuthStateChanged
} from 'firebase/auth'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

import firebaseConfig from 'configs/firebase.config'
import FileSaver from 'file-saver'

const firebaseApp = initializeApp(firebaseConfig)

const db = getFirestore(firebaseApp)
const auth = getAuth(firebaseApp)
let loggedUser = auth.currentUser;

// onAuthStateChanged(auth, (currentUser) => {
//     return loggedUser = currentUser;
// });

const storage = getStorage(firebaseApp);
const GoogleProvider = new GoogleAuthProvider();

GoogleProvider.setCustomParameters({
    prompt: 'select_account'
  });

  
const deleteUserFromApp = async (user) => {

    try {
       return await deleteUserData(user)
    } catch (error) {
        return {
            status:'failed',
            message: error
        }
        
    }
}

const loginWithGoogle = async () => {
    return signInWithPopup(auth, GoogleProvider).catch((error) => {
        return {
            status:'failed',
            error
        }
    });

  };

  const reauthWithGoogle = async () => {
    return reauthenticateWithPopup(loggedUser, GoogleProvider)
}


const deleteUserData = async (user) => {

    try {

        if(user.uid !== ''){
            
            let collectionList = []
            let imagesList = []
            let orderList = []

            //Get collection
            const collectionQuery = query(collection(db, "collections"), where("uid", "==", user.uid));
            await getDocs(collectionQuery).then((collections) => {
                collections.docs.map((collection) => {
                    let collectionId = collection.id
                    collectionList = [...collectionList, collectionId]
                    return collectionList
                })
            })

            const imagesQuery = query(collection(db, "images"), where("uid", "==", user.uid));
            await getDocs(imagesQuery).then((images) => {
                images.docs.map((image) => {
                    let imgId = image.id
                    let imgFile = image.data().name
                    imagesList = [...imagesList, {imgId, imgFile}]
                    return imagesList
                    
                })
            })

            const ordersQuery = query(collection(db, "orders"), where("uid", "==", user.uid));
            await getDocs(ordersQuery).then((orders) => {
                orders.docs.map((order) => {
                    let orderId = order.id
                    orderList = [...orderList, orderId]
                    return orderList
                })
            })
            
                    const collectionPromises = collectionList.map((collectionId) => { 
                        return deleteDoc(doc(db, "collections", collectionId))
                    })
                    
                    const orderPromises = orderList.map((orderId) => { 
                        return deleteDoc(doc(db, "orders", orderId))
                    })

                    const ImagePromises = imagesList.map((image) => { 
                        const imageRef = ref(storage, `images/${image.imgFile}`)
                        deleteDoc(doc(db, "images", image.imgId))
                        return deleteObject(imageRef)
                    })

                    await Promise.all([
                        
                        //Delete All
                        collectionPromises,
                        orderPromises,
                        ImagePromises,
                        deleteDoc(doc(db, "users", user.uid))

                    ])

                    return { status: 'success' }
                    
             }

    } catch(error) {
        console.log(error);
        return {
            status: 'failed',
            message: 'Error in removing: ', error
        }
    }

}



const addUserToDatabase = async (user) => {
    if (!user) return;
 
    try{
        await setDoc(doc(db, "users", user.uid), {
            avatar: user.avatar,
            displayName: user.displayName,
            authority: user.authority,
            email: user.email,
            uid:user.uid,
            credits: user.credits,
            createdAt: new Date(),
            lastLogin: serverTimestamp(),
        })
    } catch(error) {
        console.log('Error in creating user', error);
    }
    
  };


  const updateUserToDatabase = async (uid, displayFirstName, displayLastName) => {
    if (!displayFirstName || !displayLastName) return;
  
    try {
        
        await updateDoc(doc(db, "users", uid), {
        displayName: displayFirstName + ' ' + displayLastName
        })

        return {
            status: 'success'
        }
      } catch(error) {
        
        return {
            status: 'failed',
            message:'Error updating user: ', error
        }
      }
    
  };


  
const getUserFromDatabase = async (user) => {
    const docRef = doc(db, "users", `${user}`);
    
    try{
        return await getDoc(docRef).then(user => user.data()).catch(err=>err)
    } catch(error){
        console.log('Error getting user', error);
    }
}

const updateUserBuyCredits = async (uid, credits) => {

    try {
        
    const dbUser = await getUserFromDatabase(uid)
    
    if(dbUser){
        let previousCredits = dbUser.credits
        let newCredits = previousCredits + credits
        await updateDoc(doc(db, "users", dbUser.uid), {
            credits: newCredits
        })
    
        return {status:'success', credits:newCredits}

    } else {
        return {status:'failed'}
    }

    } catch (error) {
        return {status:'failed', message: error}
    }
    
}

const updateUserSpendCredits = async (uid, credits) => {

    try {
        
    const dbUser = await getUserFromDatabase(uid)
    
    if(dbUser){
        let previousCredits = dbUser.credits
        
        let newCredits = previousCredits - credits
        newCredits = newCredits >= 0 ? newCredits : 0
        
        await updateDoc(doc(db, "users", dbUser.uid), {
            credits: newCredits
        })
        
        return {status:'success', credits:newCredits}

    } else {
        return {status:'failed'}
    }

    } catch (error) {
        return {status:'failed', message: error}
    }
    
}

  const saveOrderToDatabase = async (uid, order, credits) => {
    
    if (!uid) return {status: 'failed', message: 'User Error'}
    //const order = fullOrder.order

    try {

        const addToDB = await addDoc(collection(db, "orders"), {
            uid: uid, 
            order: order,
            credits: credits,
            createdAt: new Date()
        })

        if(addToDB.id){
            return {
                status: 'success',
                responseId : addToDB.id
            }
        } 

        return {
            status: 'failed'
        }
        
    } catch(error) {
        return {
            status: 'failed',
            message: 'Error in saving response: ', error
        }
    }
    
  }


  const updateUserSubscriptionDatabase = async (uid, subscription) => {

    try {
        
    const dbUser = await getUserFromDatabase(uid)
    
    if(dbUser){
        const currentDate = new Date();
        const subscriptionExpirationDate = new Date(currentDate);
        
        let previousCredits = dbUser.credits
        
        await updateDoc(doc(db, "users", dbUser.uid), {
            credits: subscription.value === 'starter' ? 10000 + previousCredits : 0,
            tier: subscription.value,
            subscription: subscription.value === 'starter' ? 'inactive' : 'active',
            subscriptionExpires: subscriptionExpirationDate.setMonth(currentDate.getMonth() + 1)
        })
    
        return {status:'success', credits: subscription.value === 'starter' ? 10000 + previousCredits : 0}

    } else {
        return {status:'failed'}
    }

    } catch (error) {
        return {status:'failed', message: error}
    }
    
}


  const saveBrandVoice = async (uid, brandVoiceId, brandName, brandContent, brandVoice) => {
    
    if (!uid) return {status: 'failed', message: 'User Error'}
    
    try {

        if(brandVoiceId !== ''){
            
            const colQuery = doc(db, "brand_voices", brandVoiceId);
            const fetchDoc = await getDoc(colQuery)

            if(fetchDoc.data().uid === uid){

            await updateDoc(colQuery, {
                brandContent: brandContent, 
                brandName: brandName,
                brandVoice: brandVoice,
            });

            return {
                    status: 'success',
                    responseId : brandVoiceId
                }
            }
        }

        

        if(brandVoiceId === ''){

            const addToDB = await addDoc(collection(db, "brand_voices"), {
                uid: uid,
                brandContent: brandContent, 
                brandName: brandName,
                brandVoice: brandVoice,
                dateCreated: new Date()
            })
    
            if(addToDB.id){
                return {
                    status: 'success',
                    responseId : addToDB.id
                }
            }  else {
                return {
                    status: 'failed'
                }
            }
        
        }
        
        
    } catch(error) {
        return {
            status: 'failed',
            message: 'Error in saving Brand Voice'
        }
    }
    
  }


  const deleteBrandVoice = async (brandVoiceId, uid) => {

    if(brandVoiceId === '' || uid === ''){
        return {
            status: 'failed',
            message: 'Error in collection parameters'
        }
    }

    try {

        if(brandVoiceId !== ''){
            
            //Get collection
            const colQuery = doc(db, "brand_voices", brandVoiceId);
            const fetchDoc = await getDoc(colQuery)

            if(fetchDoc.data()){
                if(fetchDoc.data().uid === uid){
                    
                    deleteDoc(doc(db, "brand_voices", brandVoiceId))
                    return { status: 'success' }
                    
                } else {
                    return { status: 'failed' }
                }

            } else {
                    return {
                        status: 'failed',
                        message: 'Error while deleting Brand Voice.'
                    }
                }
            }

    } catch(error) {
        console.log(error);
        return {
            status: 'failed',
            message: 'Error while deleting Brand Voice'
        }
    }

}
  
  const createCollection = async (collectionData, collectionId, uid) => {

    try {

        if(collectionId !== ''){
            
            const colQuery = doc(db, "collections", collectionId);
            const fetchDoc = await getDoc(colQuery)

            if(fetchDoc.data().uid === uid){

                await updateDoc(colQuery, collectionData);
                return {
                        status: 'success',
                        responseId : collectionId
                    }
                }
            }

        if(collectionId === ''){

            const addToDB = await addDoc(collection(db, "collections"),  collectionData)
        
            if(addToDB.id){
                return {
                    status: 'success',
                    responseId : addToDB.id
                }
            } 
        }

    } catch(error) {
        console.log(error);
        return {
            status: 'failed',
            message: 'Error in creating collection: ', error
        }
    }

}


const deleteCollection = async (collectionId, uid) => {

    if(collectionId === '' || uid === ''){
        return {
            status: 'failed',
            message: 'Error in collection parameters'
        }
    }

    try {

        if(collectionId !== ''){
            
            let imagesList = []

            //Get collection
            const colQuery = doc(db, "collections", collectionId);
            const fetchDoc = await getDoc(colQuery)

            if(fetchDoc.data()){
                if(fetchDoc.data().uid === uid){
                    
                    //Get images from collectionId
                    const imgQuery = query(collection(db, "images"), where("collectionId", "==", collectionId));
                    
                    await getDocs(imgQuery).then((images) => {
                        images.docs.map((image) => {
                            let imgId = image.id
                            let imgFile = image.data().name
                            imagesList = [...imagesList, {imgId, imgFile}]
                            return imagesList
                        })
                    })
                    
                    const promises = imagesList.map((image) => { 
                        const imageRef = ref(storage, `images/${image.imgFile}`)
                        deleteObject(imageRef)
                        return deleteDoc(doc(db, "images", image.imgId))
                    })
                    
                    await Promise.all([
                        
                        //Delete collection
                        await deleteDoc(colQuery, collectionId), 
                        
                        //Delete Images
                        promises
                    ])

                    return { status: 'success' }
                    
                } else {
                    return { status: 'failed' }
                }

            } else {
                    return {
                        status: 'failed',
                        message: 'Error in deleting collection'
                    }
                }
            }

    } catch(error) {
        console.log(error);
        return {
            status: 'failed',
            message: 'Error in deleting collection: ', error
        }
    }

}



const saveImageToDb = async (item) => {
    
    try {

        const addToDB = await addDoc(collection(db, "images"),  item)
            
        if(addToDB.id){
            return {
                status: 'success',
                responseId : addToDB.id
            }
        }
        
    } catch(error) {
        console.log(error);
        return {
            status: 'failed',
            message: 'Error in saving image: ', error
        }
    }
    
  }


   const getUserCollections = async (uid) => {

    let collections = []
    const colQuery = query(collection(db, "collections"), where("uid", "==", uid), orderBy("dateCreated", "asc"));
    
    await Promise.all([
        
            await getDocs(colQuery).then((result) => {
                result.docs?.map((collectionData) => {
                    return collections = [...collections, {collectionData: collectionData.data(), id: collectionData.id}]
                })
            return collections
            })
        ])
    return collections

}

const getUserBrandVoices = async (uid) => {

    let brandVoices = []
    let colQuery = query(collection(db, "brand_voices"), where("uid", "==", uid), orderBy("dateCreated", "asc"));
    
    // const user = await getUserFromDatabase(uid)

    // if(user.tier === 'basic' || user.tier === 'starter') {
    //     colQuery = query(collection(db, "brand_voices"), where("uid", "==", uid), orderBy("dateCreated", "asc"), limit(user.tier === 'basic' ? 1 : 3));
    // } else {
        
    // } 
    
    await Promise.all([
                
            await getDocs(colQuery).then((result) => {
                result.docs?.map((brandVoicesData) => {
                    return brandVoices = [...brandVoices, {brandVoicesData: brandVoicesData.data(), id: brandVoicesData.id}]
                })
            return brandVoices
            })
        ])
    
    
    return brandVoices

}




const getBrandVoiceData = async (brandVoiceId) => {

    const colQuery = doc(db, "brand_voices", brandVoiceId);
    
    try {

        const fetchDoc = await getDoc(colQuery)

        if(!fetchDoc.exists()){
            return {status: 'failed'}
        }

        return {
            status: 'success',
            brandVoiceData: fetchDoc.data()
        } 
    
    } catch (error) {
        return {
            status: 'failed',
            message: error
        }
    }   
    
        

}

    const getCollectionData = async (collectionId) => {

        const colQuery = doc(db, "collections", collectionId);
        
        try {

            const fetchDoc = await getDoc(colQuery)

            if(!fetchDoc.exists()){
                return {status: 'failed'}
            }

            return {
                status: 'success',
                collectionData: fetchDoc.data()
            } 
        
        } catch (error) {
            return {
                status: 'failed',
                message: error
            }
        }   
        
            
    
    }


    const getBrandVoicesAdmin = async (userId = null) => {

    let brandVoicesList = []
    let itemCollection

    if(userId !== null){
        itemCollection = query(collection(db, "brand_voices"), where("uid", "==", userId), orderBy("dateCreated", "asc"), limit(8))
    } else {
        itemCollection = query(collection(db, "brand_voices"), orderBy("dateCreated", "desc"), limit(5));
    }

    const brandVoices = await getDocs(itemCollection)

    brandVoices.docs.map( async (item) => {
        
        let brandVoiceId = item.id
        let brandVoiceUserId = item.data().uid
        let brandName = item.data().brandName
        let dateCreated = item.data().dateCreated

        brandVoicesList = [...brandVoicesList, {brandName, brandVoiceId, brandVoiceUserId, dateCreated}]
        return brandVoicesList
    })

    return brandVoicesList
    }


    const getUsersAdmin = async () => {

        let userList = []
        const lastUsers = query(collection(db, "users"), orderBy("createdAt", "desc"), limit(4));
            
        const users = await getDocs(lastUsers)
    
        users.docs.map( async (user) => {
            
            let userId = user.id
            let name = user.data().displayName
            let email = user.data().email
            let credits = user.data().credits
            let createdAt = user.data().createdAt
            let avatar = user.data().avatar
            
            userList = [...userList, {userId, name, email, credits, createdAt, avatar}]
            return userList
        })
    
        return userList
        }



        const getOrdersAdmin = async (userId = null) => {

            let orderList = []
            let lastOrders

            if(userId !== null){
                lastOrders = query(collection(db, "orders"), where("uid", "==", userId), orderBy("createdAt", "desc"), limit(4));
            } else {
                lastOrders = query(collection(db, "orders"), orderBy("createdAt", "desc"), limit(4));
            }

            const orders = await getDocs(lastOrders)
        
            orders.docs.map((order) => {
                
                // const docRef = doc(db, "users", order.userId);
                // const user = await getDoc(docRef).then(user => user).catch(err=>err)
                // let userName = user.data().displayName
                
                let orderId = order.id
                let userId = order.data().uid
                let credits = order.data().credits
                let createdAt = order.data().createdAt
                let payerFirstName = order.data().order.payer.name.given_name
                let payerLastName = order.data().order.payer.name.surname
                let payerName = payerFirstName + ' ' + payerLastName
                let amount = order.data().order.purchase_units[0].amount.value

                orderList = [...orderList, {orderId, payerName, amount, userId, credits, createdAt}]
                return orderList
            })

        return orderList
        }



  const getImageFromCollection = async (collectionId) => {
    
    
    let imgUrl =''
    const itemCollection = query(collection(db, "images"), where("collectionId", "==", collectionId), orderBy("dateCreated", "asc"), limit(1));
       
    await getDocs(itemCollection).then((images) => {
        images.docs.map((image) => {
            imgUrl = image.data().url
            return imgUrl
        })
    })
    return imgUrl
  }


  const getImagesFromCollectionId = async (collectionId) => {
    
    let imagesList = []
    const itemCollection = query(collection(db, "images"), where("collectionId", "==", collectionId), orderBy("dateCreated", "asc"));
        
    await getDocs(itemCollection).then((images) => {
        images.docs.map((image) => {
            let imgId = image.id
            let imgUrl = image.data().url
            let imgName = image.data().name
            let projectName = image.data().projectName
            
            imagesList = [...imagesList, {imgUrl, imgName, imgId, projectName}]
            return imagesList
        })
    })
    
    return imagesList
  }

   
  const getImageFromImageId = async (imageId) => {
    
    const colQuery = doc(db, "images", imageId);
        
        try {

            const fetchDoc = await getDoc(colQuery)
            if(!fetchDoc.exists()){
                return {status: 'failed'}
            }
            return {
                status: 'success',
                ImageData: fetchDoc.data()
            } 
        
        } catch (error) {
            return {
                status: 'failed',
                message: error
            }
        }   
    }


    const downloadImageFileFromDb = async (imageName) => {
        
        try {
            const url = await getDownloadURL(ref(storage, `images/${imageName}`));
            const response = await fetch(url)
            
            const blob = await response.blob();
            const file = new File([blob], imageName, { type: 'image/png' });
            
            return FileSaver.saveAs(file);
            
        } catch (error) {
            // Handle any errors
            console.error(error);
            return {
                status: 'failed'
            }
        }
    }


    const getUserOrders = async (uid) => {

        let orders = []
        const orderQuery = query(collection(db, "orders"), where("uid", "==", uid), orderBy("createdAt", "desc"), limit(10));
        
        await Promise.all([
            
                await getDocs(orderQuery).then((result) => {
                    result.docs?.map( async (order) => {
                        let orderData = order.data()
                        return orders = [...orders, {orderData, id: order.id}]
                    })
                return orders
                })
            ])
        return orders
    }



    const getCountOfDocuments = async (collectionName, userId = null) => {
        
        let coll

        if(userId !== null){
            coll = query(collection(db, collectionName), where("uid", "==", userId));
        } else {
            coll = query(collection(db, collectionName));
        }
        
        const snapshot = await getCountFromServer(coll);
        const finalCount = snapshot.data().count
        return finalCount
    }


    const getAdminAnalytics = async () => {

        let responsesList = {}
        const colQuery = query(collection(db, "admin_analytics"), limit(1));
            
            await getDocs(colQuery).then((result) => {
                result.docs?.map((responsesData) => {
                    const adminData = responsesData.data()
                    return responsesList = {...responsesList, adminData}
                })
            return responsesList
            })
            
        return responsesList
        
    }


    const updateGeneratedWords = async (newWords) => {
        
        try {
            const colQuery = query(collection(db, "admin_analytics"), limit(1));
            const querySnapshot = await getDocs(colQuery);
    
            let responsesList = {};
            querySnapshot.forEach((doc) => {
                const adminData = doc.data();
                responsesList = { ...responsesList, ...adminData, id: doc.id };
            });
    
            let previousWords = responsesList.words_generated;
            let newWordCount = previousWords + newWords;
    
            await updateDoc(doc(db, "admin_analytics", responsesList.id), {
                words_generated: newWordCount
            });

            return { status: 'success' };
        
        } catch (error) {
            return { status: 'failed', message: error };
        }
    };
    


    const updateTotalRevenue = async (amount) => {
        
        try {
        
            let responsesList = {}
            const colQuery = query(collection(db, "admin_analytics"), limit(1));
             
            await getDocs(colQuery).then((result) => {
                result.docs?.map((responsesData) => {
                    const adminData = responsesData.data()
                    return responsesList = {...responsesList, adminData, id:responsesData.id }
                })
            })
            
            let previousAmount = responsesList.adminData.total_revenue
            let newAmount = previousAmount + amount
            
            await updateDoc(doc(db, "admin_analytics", responsesList.id), {
                total_revenue: newAmount
            })
        
            return {status:'success'}
        
        } catch (error) {
            return {status:'failed', message: error}
        } 
    }






    const getUserSavedResponses = async (uid, tool) => {

        let responsesList = []
        const colQuery = query(collection(db, "saved_responses"), where("uid", "==", uid), where("tool", "==", tool), orderBy("dateCreated", "asc"));
        
        await Promise.all([
            
                await getDocs(colQuery).then((result) => {
                    result.docs?.map((responsesData) => {
                        return responsesList = [...responsesList, { ...responsesData.data(), id: responsesData.id }]
                    })
                return responsesList
                })
            ])
        
        
        return responsesList
    
    }


    const saveResponseToDatabase = async (uid, text, tool, category, values) => {
    
        if (!uid) return {status: 'failed', message: 'User Error'}
        
        try {
    
            const addToDB = await addDoc(collection(db, "saved_responses"), {
                uid: uid, 
                text: text,
                tool: tool,
                category: category,
                dateCreated: new Date(),
                prompt: values
            })
    
            if(addToDB.id){
                return {
                    status: 'success',
                    responseId : addToDB.id
                }
            }
            
        } catch(error) {
            return {
                status: 'failed',
                message: 'Error in saving response: ', error
            }
        }
        
      };
    
      const unSaveResponseFromDatabase = async (uid, id) => {
        
        if (!uid) return {status: 'failed', message: 'User Error'}
      
        try {
    
            await deleteDoc(doc(db, "saved_responses", id))
            return {
                status: 'success',
            }
            
        } catch(error) {
            return {
                status: 'failed',
                message: 'Error in unsaving response: ', error
            }
          }
        
      };


export {
    db,
    auth,
    loggedUser,
    storage,
    doc,
    updateDoc,
    getDoc,
    collection,
    addUserToDatabase,
    getUserFromDatabase,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
    createUserWithEmailAndPassword,
    serverTimestamp,
    saveOrderToDatabase, 
    saveImageToDb,
    getUserCollections,
    getUserBrandVoices,
    deleteBrandVoice,
    getImageFromCollection,
    createCollection, 
    getImagesFromCollectionId,
    getBrandVoiceData,
    getCollectionData, 
    deleteCollection, 
    getImageFromImageId,
    downloadImageFileFromDb,
    updateUserToDatabase,
    loginWithGoogle,
    deleteUserFromApp,
    reauthWithGoogle,
    getUserOrders,
    getCountOfDocuments,
    updateUserBuyCredits,
    updateUserSpendCredits,
    getBrandVoicesAdmin,
    getUsersAdmin,
    getOrdersAdmin,
    saveResponseToDatabase, 
    unSaveResponseFromDatabase,
    saveBrandVoice,
    getUserSavedResponses,
    updateUserSubscriptionDatabase,
    getAdminAnalytics,
    updateGeneratedWords,
    updateTotalRevenue
}