import React from 'react'
import authRoute from './authRoute'
import pagesRoute from './pagesRoute'
import { ADMIN, USER } from 'constants/roles.constant'


const landingRoutes =  [
    {
        key: '/',
        path: '/',
        component: React.lazy(() => import('views/pages/Landing/LandingHome')),
        authority: [],
        meta: {
            layout: 'simple',
            pageContainerType: 'contained',
            footer: true
        }
    },
    {
        key: '/terms',
        path: '/terms',
        component: React.lazy(() => import('views/pages/Terms/Terms')),
        authority: [],
        meta: {
            layout: 'simple',
            pageContainerType: 'contained',
            footer: true
        }
    },
]

export const publicRoutes = [
    ...authRoute,
    ...pagesRoute,
    ...landingRoutes
]

export const protectedRoutes = [
    ...landingRoutes,
    {
        key: 'dashboard',
        path: '/dashboard',
        component: React.lazy(() => import('views/Dashboard')),
        authority: [ADMIN, USER],
    },
    {
        key: 'blogPostIntroduction',
        path: '/:category/:urltool',
        component: React.lazy(() => import('views/ai/ToolContainer')),
        authority: [ADMIN, USER],
    },
    {
        key: 'brand-voice',
        path: `/brand-voice`,
        component: React.lazy(() => import('views/pages/BrandVoice')),
        authority: [ADMIN, USER],
    },
    {
        key: 'brand-voice-new',
        path: `/brand-voice/new`,
        component: React.lazy(() => import('views/pages/BrandVoice/BrandVoiceNew')),
        authority: [ADMIN, USER],
    },
    {
        key: 'brand-voice-new',
        path: `/brand-voice/edit/:brandVoiceId`,
        component: React.lazy(() => import('views/pages/BrandVoice/BrandVoiceNew')),
        authority: [ADMIN, USER],
    },
    {
        key: 'userCollection',
        path: '/collection/:collectionId/',
        component: React.lazy(() => import('views/pages/Collection/Collection')),
        authority: [ADMIN, USER],
    },
    {
        key: 'collectionImage',
        path: '/image/:imageId/',
        component: React.lazy(() => import('views/pages/Collection/CollectionImageExpanded')),
        authority: [ADMIN, USER],
    },
    {
        key: 'appsSales.dashboard',
        path: `/analytics`,
        component: React.lazy(() => import('views/sales/SalesDashboard')),
        authority: [ADMIN, USER],
    },
    {
        key: 'appsAccount.settings',
        path: '/account/settings/:tab',
        component: React.lazy(() => import('views/account/Settings')),
        authority: [ADMIN, USER],
    },
    {
        key: 'credits',
        path: '/credits',
        component: React.lazy(() => import('views/pages/Subscribe/Credits')),
        authority: [ADMIN, USER],
        meta: {
            pageContainerType: 'contained',
            footer: false
        }
    },
    {
        key: 'subscriptionSuccess',
        path: '/credits/success',
        component: React.lazy(() => import('views/pages/Subscribe/SubscriptionSuccess')),
        authority: [ADMIN, USER],
        meta: {
            pageContainerType: 'contained',
            footer: false
        }
    },
    {
        key: 'admin',
        path: '/admin',
        component: React.lazy(() => import('views/pages/Admin/Admin')),
        authority: [ADMIN],
        meta: {
            pageContainerType: 'contained',
            footer: false
        }
    },
    {
        key: 'user',
        path: '/admin/user/:userId',
        component: React.lazy(() => import('views/pages/Admin/UserAdmin')),
        authority: [ADMIN],
        meta: {
            pageContainerType: 'contained',
            footer: false
        }
    }
]